.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 60px;
  padding: 5%;
}
@media (max-width: 1820px) {
  .img-grid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    padding: 5%;
  }
}
@media (max-width: 1453px) {
  .img-grid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    padding: 5%;
  }
}
@media (max-width: 1050px) {
  .img-grid {
    margin: 20px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    padding: 5%;
  }
}
@media (max-width: 768px) {
  .img-grid {
    margin: 20px 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    padding: 5%;
  }
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  width: 300px;
  height: 650px;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 100%;
  min-height: 60%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
